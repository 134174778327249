.formContainer {
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    padding-left: 100px;
    margin: auto;
    width: 50%;
    background-color: white;
    padding: 20px;
    align-items: center;
}

.hyper-link {
    font-size: .7em;
}