.button-container {
  margin-bottom: 20px;
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #f9f9f9;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
  text-decoration: none;
  color: #3e414b;
}

.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.side-button.active {
  background: #fd0202;
  color: #fffce1;
}
a:active,
a:hover {
  outline: 0;
}

a:hover,
a:focus {
  color: #c94e50;
}

.image-owner {
  font-size: x-small;
}
