.footer {
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  align-items: center;
  background-color: #222;
  color: #999;
  font-size: 0.85em;
  justify-content: space-between;
  margin: 60px 0px 0px 0px;
  align-content: space-between;
}
.logo {
  width: 60px;
  text-align: left;
}
.footerLink {
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
}

.footerLink p {
  color: #fefefe;
  margin-left: 10px;
  font-weight: bold;
  font-size: 1.2em;
}

select {
  appearance: none;
  border: 1px solid #777;
  border-radius: 0.4em;
  color: #999;
  font-size: 0.8em;
  margin: 1.2em 0 1.2em 1.6em;
  padding: 0.6em 0.8em;
  width: fit-content;
  text-align: center;
}
select option {
  min-height: 1.2em;
}
