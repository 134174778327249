.typography {
    font-weight: bolder !important;
}

.typography-answer {
    color: red;
}

.accordionDetails {
    display: flex;
    flex-direction: column;
    text-align:left ;
}